import React from "react";
import { Layout } from "../components/Layout/layout";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import Button from "../components/atoms/Button";
import arrowIcon from "../images/svg/arrow_right_24px.svg";
import downloadPdf from "../images/svg/save_alt_24px_white.svg";

const styleguide = () => (
  <Layout>
    <Wrapper>
      <h1>Styleguide</h1>
      <h1>H1 title</h1>
      <p>
        I'm baby unicorn fam tacos helvetica poke. Quinoa hashtag sustainable, vinyl helvetica photo
        booth before they sold out wayfarers meggings seitan leggings fixie sartorial coloring book.
      </p>
      <p>
        Deep v lumbersexual normcore succulents selfies vaporware locavore kombucha cloud bread
        shaman whatever cronut chia.
      </p>
      <h2>H2 title</h2>
      <p>
        I'm baby unicorn fam tacos helvetica poke. Quinoa hashtag sustainable, vinyl helvetica photo
        booth before they sold out wayfarers meggings seitan leggings fixie sartorial coloring book.
      </p>
      <p>
        Deep v lumbersexual normcore succulents selfies vaporware locavore kombucha cloud bread
        shaman whatever cronut chia.
      </p>
      <h3>H3 title</h3>
      <p>
        I'm baby unicorn fam tacos helvetica poke. Quinoa hashtag sustainable, vinyl helvetica photo
        booth before they sold out wayfarers meggings seitan leggings fixie sartorial coloring book.
      </p>
      <p>
        Deep v lumbersexual normcore succulents selfies vaporware locavore kombucha cloud bread
        shaman whatever cronut chia.
      </p>
      <h4>H4 title</h4>
      <p>
        I'm baby unicorn fam tacos helvetica poke. Quinoa hashtag sustainable, vinyl helvetica photo
        booth before they sold out wayfarers meggings seitan leggings fixie sartorial coloring book.
      </p>
      <p>
        Deep v lumbersexual normcore succulents selfies vaporware locavore kombucha cloud bread
        shaman whatever cronut chia.
      </p>
      <h5>H5 title</h5>
      <p>
        I'm baby unicorn fam tacos helvetica poke. Quinoa hashtag sustainable, vinyl helvetica photo
        booth before they sold out wayfarers meggings seitan leggings fixie sartorial coloring book.
      </p>
      <p>
        Deep v lumbersexual normcore succulents selfies vaporware locavore kombucha cloud bread
        shaman whatever cronut chia.
      </p>

      <div>
        <Button
          marginBottom
          primary
          label={`Test button`}
          dataQa={`back-step`}
        />
      </div>
      <div>
        <Button
          marginBottom
          primaryAlt
          label={`Test button`}
          dataQa={`back-step`}
        />
      </div>
      <div>
        <Button
          marginBottom
          primary
          lg
          link="#"
          label={`Test button`}
          icon={downloadPdf}
          iconLeft={true}
          data-qa={"download-pdf"}
          download={true}
        />
      </div>
      <div>
        <Button
          icon={arrowIcon}
          primary
          label={`Test button`}
          link="#"
          dataQa={`Test button form`}
        />
      </div>
      <div>
        <Button
          secondary
          label={`Test button`}
          dataQa={`back-step`}
        />
      </div>
      <div>
        <Button
          disabled={true}
          label={`Test button`}
          dataQa={`back-step`}
        />
      </div>
      <div>
        <Button
          tertiary
          label={`Test button`}
          dataQa={`back-step`}
        />
      </div>
    </Wrapper>
  </Layout>
);

export default styleguide;
